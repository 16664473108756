/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'share-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 2.5a2.5 2.5 0 11.603 1.628l-6.718 3.12a2.5 2.5 0 010 1.504l6.718 3.12a2.5 2.5 0 11-.488.876l-6.718-3.12a2.5 2.5 0 110-3.256l6.718-3.12A2.5 2.5 0 0111 2.5"/>',
    },
});
